<template>
  <div class="vg_wrapper">
    <el-card>
      <el-row>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
        ><i class="el-icon-refresh-right"></i> 刷新
        </el-button>
      </el-row>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" :data="tableData" border v-loading="loading" class="mtrTab">
            <el-table-column label="序号" width="50px" align="center">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column label="时间" prop="requ_push_time" width="150px" :formatter="formatDate"/>
            <!--            <el-table-column label="文本" prop="requ_push_text" width="900px" show-overflow-tooltip/>-->
            <el-table-column label="文本" prop="requ_push_text" width="auto">
              <template v-slot="scope">
                <el-tooltip popper-class="item" effect="dark" placement="top">
                  <div slot="content" class="item">
                    {{ scope.row.requ_push_text }}
                  </div>
                  <span
                      style="
                      display: -webkit-box;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;
                      white-space: pre-line;
                    "
                  >{{ scope.row.requ_push_text }}</span
                  >
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column label="操作" align="center" width="140px">
              <template slot-scope="scope">
                <div style="display: flex">
                  <el-link
                      class="vg_mr_8 vg_cursor"
                      type="primary"
                      size="mini"
                      @click="
                      jump('/smpl_list', {
                        perm_id: 112,
                        form_id: scope.row.requ_id,
                        smpl_no: scope.row.smpl_no,
                        smpl_suffix: scope.row.smpl_suffix
                      })
                    "
                  >查看详情
                  </el-link
                  >
                  <el-link class="vg_cursor" type="success" size="mini" @click="goRequList(scope.row)">已处理</el-link>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get} from '@api/request';
import {requAPI} from '@api/modules/requ';
import bus from '@/components/common/bus';

export default {
  name: 'MailPush9003',
  data() {
    return {
      tableData: [],
      loading: true
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    bus.$on('refreshRequPush9005', msg => {
      this.initData();
    });
  },
  methods: {
    initData() {
      this.getRequPush();
    },
    //获取数据
    getRequPush() {
      get(requAPI.getRequPush, {requ_push_state: 2})
          .then(res => {
            if (res.data.code === 0) {
              this.tableData = res.data.data.list;
              setTimeout(() => {
                this.loading = false;
              }, 500);
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          });
    },
    //跳转RequList页面
    goRequList(row) {
      //退单提醒
      get(requAPI.updateRequPushTotal, {requpush_id: row.requpush_id})
          .then(res => {
            if (res.data.code !== 0) {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
            this.initData();
            //刷新首页推送数量
            bus.$emit('refreshReminderCount');
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          });
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toTime13(row.requ_push_time);
    },
    // 刷新按钮
    buttonRefresh() {
      this.getRequPush();
      this.loading = true;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_completed {
  color: $color-secondary-text;
}

.item {
  max-width: 1500px !important; /* 根据需要设置 */
}

.mtrTab {
  margin-top: 10px;
  cursor: pointer;
}
</style>
